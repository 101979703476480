// import {BinanceWalletConnector} from '@pancakeswap/wagmi/connectors/binanceWallet'
// import {getWagmiConnectorV2} from '@binance/w3w-wagmi-connector-v2'
// import {BloctoConnector} from '@pancakeswap/wagmi/connectors/blocto'
import {TrustWalletConnector} from '@pancakeswap/wagmi/connectors/trustWallet'
import {BitgetWalletConnector} from '@pancakeswap/wagmi/connectors/bitgetWallet'
import {bsc, mainnet, bscTestnet} from 'wagmi/chains'
import {Chain, configureChains, createClient} from 'wagmi'
import memoize from 'lodash/memoize'
import {CoinbaseWalletConnector} from 'wagmi/connectors/coinbaseWallet'
import {InjectedConnector} from 'wagmi/connectors/injected'
import {MetaMaskConnector} from 'wagmi/connectors/metaMask'
import {WalletConnectConnector} from 'wagmi/connectors/walletConnect'
// import {LedgerConnector} from 'wagmi/connectors/ledger'
import {jsonRpcProvider} from 'wagmi/providers/jsonRpc'
import {SafeConnector} from './safeConnector'


const opBNB: Chain = {
  id: 204,
  name: 'opBNB Mainnet',
  network: 'opbnb',
  rpcUrls: {
    default: {
      http: ['https://opbnb-rpc.publicnode.com'],
      webSocket: ['wss://opbnb-rpc.publicnode.com']
    },
  },
  blockExplorers: {
    default: {name: 'opBNB Explorer', url: 'https://mainnet.opbnbscan.com'},
  },
  nativeCurrency: {
    name: 'Binance Chain Native Token',
    symbol: 'BNB',
    decimals: 18,
  },
}

const CHAINS = process.env.NODE_ENV === 'production' ? [bsc, mainnet] : [bsc, mainnet, bscTestnet, opBNB]

const getNodeRealUrl = (networkName: string, isProxy = false) => {
  let host = null

  switch (networkName) {
    case 'homestead':
      if (process.env.NEXT_PUBLIC_NODE_REAL_API_ETH) {
        host = `eth-mainnet.nodereal.io/v1/${process.env.NEXT_PUBLIC_NODE_REAL_API_ETH}`
      }
      break
    case 'bsc':
      if (process.env.NEXT_PUBLIC_NODE_REAL_API_BSC) {
        host = `bsc-mainnet.nodereal.io/v1/${process.env.NEXT_PUBLIC_NODE_REAL_API_BSC}`
      }
      break

    default:
      host = null
  }

  if (!host) {
    return null
  }

  const url = `https://${host}`
  return {
    http: isProxy ? `/api/node/${networkName}` : url,
    webSocket: url.replace(/^http/i, 'wss').replace('.nodereal.io/v1', '.nodereal.io/ws/v1'),
  }
}

export const {provider, chains} = configureChains(CHAINS, [
  jsonRpcProvider({
    rpc: (chain) => {
      if (process.env.NODE_ENV !== 'production' && chain.id === bsc.id) {
        return {http: 'https://bsc-dataseed.binance.org'}
      }

      if (process.env.NODE_ENV !== 'production' && chain.id === mainnet.id) {
        return {http: 'https://cloudflare-eth.com'}
      }

      return getNodeRealUrl(chain.network, false) || {http: chain.rpcUrls.default.http[0]}
    },
  }),
  jsonRpcProvider({
    rpc: chain => {
      if (process.env.NODE_ENV === 'production' && chain.id === bsc.id) {
        return {http: 'https://rpc.muskswap.club/bsc'}
      }

      if (process.env.NODE_ENV === 'production' && chain.id === mainnet.id) {
        return {http: 'https://rpc.muskswap.club/eth'}
      }

      return {http: chain.rpcUrls.default.http[0]}
    }
  }),
  jsonRpcProvider({
    rpc: chain => ({http: chain.rpcUrls.default.http[0]})
  })
])

export const injectedConnector = new InjectedConnector({
  chains,
  options: {
    shimDisconnect: false,
    shimChainChangedDisconnect: true,
  },
})

export const coinbaseConnector = new CoinbaseWalletConnector({
  chains,
  options: {
    appName: 'MuskSwap',
    appLogoUrl: 'https://muskswap.club/logo.png',
  },
})

export const walletConnectConnector = new WalletConnectConnector({
  chains,
  options: {
    qrcode: true,
  },
})

export const walletConnectNoQrCodeConnector = new WalletConnectConnector({
  chains,
  options: {
    qrcode: false,
  },
})

export const metaMaskConnector = new MetaMaskConnector({
  chains,
  options: {
    shimDisconnect: false,
    shimChainChangedDisconnect: true,
  },
})

// const bloctoConnector = new BloctoConnector({
//   chains,
//   options: {
//     defaultChainId: 56,
//     appId: 'e2f2f0cd-3ceb-4dec-b293-bb555f2ed5af',
//   },
// })

// const ledgerConnector = new LedgerConnector({
//   chains,
// })

// export const bscConnector = new BinanceWalletConnector({chains})

export const trustWalletConnector = new TrustWalletConnector({
  chains,
  options: {
    shimDisconnect: false,
    shimChainChangedDisconnect: true,
  },
})

const bitgetWalletConnector = new BitgetWalletConnector({
  chains
})

export const client = createClient({
  autoConnect: false,
  provider,
  connectors: [
    injectedConnector,
    new SafeConnector({chains}),
    metaMaskConnector,
    coinbaseConnector,
    walletConnectConnector,
    // bscConnector,
    // bloctoConnector,
    // ledgerConnector,
    trustWalletConnector,
    bitgetWalletConnector
  ],
})

export const CHAIN_IDS = chains.map((c) => c.id)

export const isChainSupported = memoize((chainId: number) => CHAIN_IDS.includes(chainId))
export const isChainTestnet = memoize((chainId: number) => chains.find((c) => c.id === chainId)?.testnet)
